import { createLogger } from "@civic/auth";

export const logger: {
  web3: {
    metakeep: ReturnType<typeof createLogger>;
    wagmi: ReturnType<typeof createLogger>;
    provider: ReturnType<typeof createLogger>;
    config: ReturnType<typeof createLogger>;
  };
} = {
  web3: {
    metakeep: createLogger("web3:metakeep"),
    wagmi: createLogger("web3:wagmi"),
    provider: createLogger("web3:provider"),
    config: createLogger("web3:config"),
  },
};
