// --------------------------------------------------------------
// Fallback that returns a dummy connection hook, used if the solana wallet adapter is not present
// --------------------------------------------------------------
import type { Connection } from "@solana/web3.js";

export const connectionFallback = {
  connection: undefined,
};
export const useConnectionInternal: () => { connection?: Connection } = () =>
  connectionFallback;
