import { ChainType } from "@civic/auth-web3";
import { createContext, useContext, useState } from "react";

type ChainTypeContext = {
  chainType: ChainType;
  setChainType: (chainType: ChainType) => void;
};
const chainTypeContext = createContext<ChainTypeContext>({
  setChainType: () => {},
  chainType: "ethereum",
});

export const ChainTypeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [chainType, setChainType] = useState<ChainType>("ethereum");
  return (
    <chainTypeContext.Provider value={{ chainType, setChainType }}>
      {children}
    </chainTypeContext.Provider>
  );
};

export const useChainType = () => useContext(chainTypeContext);
