import type {
  CivicWeb3ClientConfig,
  EVMChainRpcUrls,
  ExistingWeb3UserContext,
  NewWeb3UserContext,
  NonEmptyArray,
  RpcUrls,
  Web3UserContextType,
} from "../types.js";
import { type Chain } from "viem";
import * as chains from "viem/chains";

// to reduce bundle size we can disable loading all chains via an env var
// const chainMap = () => import("viem/chains");
// load a list of chains from viem.
const allChains = Object.values(chains) as Chain[];

export const ICON =
  "data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjIxOSIgdmlld0JveD0iMCAwIDIxOCAyMTkiIHdpZHRoPSIyMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iI2ZmZiI+PHBhdGggZD0ibTEzMS4yNDcgOTIuNzk3N2MwLTEyLjE2ODktOS44NjUtMjIuMDMzMS0yMi4wMzMtMjIuMDMzMS0xMi4xNjg3IDAtMjIuMDMzNSA5Ljg2NDEtMjIuMDMzNSAyMi4wMzMxIDAgOC41MTQzIDQuODM4IDE1Ljg4NzMgMTEuOTA4MSAxOS41NTUzbC04LjE2NTcgMzUuMzExaDM2LjU4MTFsLTguMTY1LTM1LjMxMWM3LjA2OS0zLjY2OCAxMS45MDgtMTEuMDQxIDExLjkwOC0xOS41NTUzeiIvPjxwYXRoIGQ9Im0xOTUuMzkyIDEzNi45ODNjLTQuMjc5IDMzLjIyNS0zMi43MjcgNTguOTg2LTY3LjA5OCA1OC45ODZoLTM4LjE2MTFjLTM3LjMxNjEgMC02Ny42NzUxLTMwLjM1OS02Ny42NzUxLTY3LjY3NXYtMzguMTYwM2MwLTM3LjMxNjIgMzAuMzU5LTY3LjY3NTIgNjcuNjc1MS02Ny42NzUyaDM4LjE2MTFjMzQuMzcxIDAgNjIuODE4IDI1Ljc2MjQgNjcuMDk4IDU4Ljk4NzJoMjIuNjA4Yy00LjM4Ni00NS42MzgzLTQyLjkzOC04MS40NDU3LTg5LjcwNi04MS40NDU3aC0zOC4xNjExYy00OS42OTk1IDAtOTAuMTMyOSA0MC40MzQyLTkwLjEzMjkgOTAuMTMzN3YzOC4xNjAzYzAgNDkuNyA0MC40MzM1IDkwLjEzNCA5MC4xMzI5IDkwLjEzNGgzOC4xNjExYzQ2Ljc2OCAwIDg1LjMyLTM1LjgwOCA4OS43MDYtODEuNDQ1eiIvPjwvZz48L3N2Zz4=" as const;

export const isNewUser = (
  userContext: Web3UserContextType,
): userContext is NewWeb3UserContext =>
  !Object.prototype.hasOwnProperty.call(userContext, "ethereum");

export const userHasWallet = (
  userContext: Web3UserContextType,
): userContext is ExistingWeb3UserContext =>
  !!userContext.user && !isNewUser(userContext);

const isNonEmptyArray = <T>(arr: readonly T[]): arr is NonEmptyArray<T> =>
  arr.length > 0 && arr[0] !== undefined;

/**
 * Convert a chain to a set of RPC urls by using the defaults
 * @param chain
 */
export const defaultRpcNodeUrlsForChain = (chain: Chain): RpcUrls => {
  const httpUrls = chain.rpcUrls.default.http;
  if (!isNonEmptyArray(httpUrls)) {
    throw new Error("Default rpc http urls must have at least one entry");
  }
  const wsUrls = chain.rpcUrls.default.webSocket;
  if (wsUrls !== undefined && !isNonEmptyArray(wsUrls)) {
    throw new Error(
      "Default rpc ws urls must be undefined or have at least one entry",
    );
  }

  return {
    http: httpUrls,
    webSocket: wsUrls,
  };
};

// Get the list of chain RPCs to be used by the provider.
// If an explicit list is passed in via the config, then use that,
// Otherwise use the default RPCs provided by Viem
// Since metakeep can only have one rpc per chain, we take the first each time
export const getEthereumProviderRPCsFromConfig = (
  config: CivicWeb3ClientConfig,
) => {
  const chains = Array.from(
    new Set([
      ...(config.initialChain ? [config.initialChain] : []),
      ...(config.chains ?? allChains),
    ]),
  );

  return (
    config.endpoints?.rpcs ??
    chains.reduce((acc, chain) => {
      acc[chain.id] = defaultRpcNodeUrlsForChain(chain);
      return acc;
    }, {} as EVMChainRpcUrls)
  );
};
