import type { EIP1193Provider } from "viem";
import { logger } from "../logger.js";
import { ICON } from "../walletUtils.js";

const providerInfo = {
  uuid: "9c0ce02a-23bd-45b5-a324-5fa1225950ae",
  name: "Civic Wallet",
  icon: ICON,
  rdns: "com.civic",
};

export const registerProvider = (provider: EIP1193Provider) => {
  if (typeof window === "undefined" || !window.dispatchEvent) {
    // server-side rendering path - no need to announce
    return;
  }

  const providerDetail = {
    info: providerInfo,
    provider,
  };

  logger.web3.provider.debug("EIP-6963: Announcing provider", providerDetail);

  // announce it every second
  window.setInterval(() => {
    window.dispatchEvent(
      new CustomEvent("eip6963:announceProvider", { detail: providerDetail }),
    );
  }, 1000);
};
