import type { GenericEthereumProvider } from "../../types.js";
import type { EIP1193EventMap } from "viem";
import { wrapWithEIP2255 } from "./EIP2255Provider.js";
import {
  EIP1193ProviderImpl,
  type TypedEthereumProvider,
} from "./EIP1193ProviderImpl.js";
import { createLazyProxy } from "../lazy/LazyProxy.js";
import { registerProvider } from "./discovery.js";

/**
 * Given an untyped ethereum provider, expose a provider with
 *  - EIP1193 methods (wallet connection and events)
 *  - EIP2255 methods (wallet permissions)
 *  - EIP1474 methods (standard ethereum RPC)
 * @param provider an untyped ethereum provider offering only a generic "request" method
 * @returns a typed provider implementing EIP1193, EIP2255, and EIP1474
 */
export const createTypedProvider = (
  provider: GenericEthereumProvider,
): TypedEthereumProvider => {
  const eip1193Provider = new EIP1193ProviderImpl(provider);
  return wrapWithEIP2255(eip1193Provider);
};

/**
 * Create a lazy ethereum provider that can be populated later with a provider implementing EIP1474, EIP1193, and EIP2255
 */
export const createLazyEthereumProvider = () =>
  createLazyProxy<
    TypedEthereumProvider & GenericEthereumProvider,
    EIP1193EventMap
  >(["request"]);

// created for discovery via eip6963
export const singletonEthereumProvider = createLazyEthereumProvider();
registerProvider(singletonEthereumProvider);
