import type {
  CivicWeb3ClientConfig,
  UserDetailsWithAuth,
  Wallets,
} from "../../types.js";
import { logger } from "../logger.js";
import type { User } from "@civic/auth";
import type { Web3Client } from "../Web3Client.js";
import { MetakeepEthereumWeb3Client } from "./ethereum/MetakeepEthereumWeb3Client.js";
import { MetakeepSolanaWeb3Client } from "./solana/MetakeepSolanaWeb3Client.js";
import {
  CivicMetakeepApiClient,
  type ConfigResponse,
} from "./civicApiClient.js";
import type { Address } from "viem";
import type { EthereumWeb3Client } from "../ethereum/EthereumWeb3Client.js";
import type { SolanaWeb3Client } from "../solana/SolanaWeb3Client.js";
import type { Connection } from "@solana/web3.js";

type WalletConfig = {
  // Optional solana connection object. If provided, the wallet can behave not just as a signer
  // but also send transactions, just like a browser extension wallet.
  // Adding this will automatically register the wallet using the Solana Wallet Standard for detection by
  // wallet selection UI components
  solanaConnection?: Connection;
};

export class MetakeepWeb3Client implements Web3Client {
  readonly ethereum: EthereumWeb3Client;
  readonly solana: SolanaWeb3Client;
  connected: boolean;

  constructor(
    ethereum: EthereumWeb3Client,
    solana: SolanaWeb3Client,
    private hooks: { onSessionEnd?: () => void },
  ) {
    this.ethereum = ethereum;
    this.solana = solana;
    this.connected = true;
  }

  async createWallets(): Promise<Wallets | null> {
    // currently this function just returns the existing wallets, as they are automatically created on login
    // We may choose to defer wallet creation in future to reduce costs.
    if (!this.ethereum.address || !this.ethereum.client) {
      throw new Error("Ethereum wallet not found");
    }
    return {
      ethereum: {
        address: this.ethereum.address,
        wallet: this.ethereum.client,
      },
      solana: {
        address: this.solana.address,
        wallet: this.solana.wallet,
      },
    };
  }

  public async disconnect(): Promise<void> {
    this.hooks.onSessionEnd?.();

    await this.ethereum.disconnect();
    await this.solana.disconnect();

    this.connected = false;
  }

  /**
   * Build a Web3Client, that implements the Web3Client interface using Metakeep
   * @param civicApiConfig - the configuration for calling the civic wallet service API. Any metakeep config comes from the service itself
   * @param user - the user for whom the wallet cleint is being created
   * @param onSessionEnd
   */
  static async build<TUserDetails extends UserDetailsWithAuth>(
    civicApiConfig: CivicWeb3ClientConfig & WalletConfig,
    user: User<TUserDetails>,
    onSessionEnd?: () => void,
  ): Promise<Web3Client> {
    const civicApiClient = new CivicMetakeepApiClient(user, civicApiConfig);

    // get the metakeep config either from the wallet creation request or a separate config call
    let metakeepConfig: ConfigResponse["config"];

    // decide if we need to create or retrieve the wallet, based on the presence of a wallet claim in the user
    let { ethWalletAddress, solWalletAddress } = user;

    // if the wallet addresses are not part of the user object
    // assume they don't have a wallet yet, and create one
    if (!ethWalletAddress && !solWalletAddress) {
      logger.web3.metakeep.debug(
        "No wallet present in user object - creating one",
      );
      const { wallet, config } = await civicApiClient.createWallet();
      ethWalletAddress = wallet.ethAddress;
      solWalletAddress = wallet.solAddress;
      metakeepConfig = config;
    } else {
      // no need to create a wallet, but we still need to ask the server for the config
      const { config } = await civicApiClient.getConfig();
      metakeepConfig = config;
    }

    // If the user still doesn't have a wallet, throw an error
    if (!ethWalletAddress || !solWalletAddress) {
      throw new Error(
        `Failed to create wallet: Eth address: ${ethWalletAddress}, Sol address: ${solWalletAddress}`,
      );
    }

    // create both clients in parallel
    const ethereumWeb3ClientPromise = MetakeepEthereumWeb3Client.build(
      {
        ...civicApiConfig,
        metakeep: metakeepConfig.metakeep,
      },
      user,
      ethWalletAddress as Address,
    );

    const solanaWeb3ClientPromise = MetakeepSolanaWeb3Client.build(
      {
        ...civicApiConfig,
        metakeep: metakeepConfig.metakeep,
        solanaConnection: civicApiConfig.solanaConnection,
      },
      user,
      solWalletAddress,
    );

    const ethereumClient = await ethereumWeb3ClientPromise;
    const solanaClient = await solanaWeb3ClientPromise;
    return new MetakeepWeb3Client(ethereumClient, solanaClient, {
      onSessionEnd,
    });
  }
}
