import type { SolanaWeb3Client } from "../../solana/SolanaWeb3Client.js";
import MetakeepSDK from "metakeep";
import { MetakeepSolanaWalletAdapter } from "./MetakeepSolanaWalletAdapter.js";
import { type MetakeepConfig, toMetakeepRpcUrls } from "../util.js";
import { getEthereumProviderRPCsFromConfig } from "../../walletUtils.js";
import type { CivicWeb3ClientConfig, UserDetails } from "../../../types.js";
import type { User } from "@civic/auth";
import type { Connection } from "@solana/web3.js";
import { MetakeepWalletStandardAdapter } from "../../solana/walletAdapter/standardAdapter.js";
import { singletonSolanaWalletAdapter } from "../../solana/index.js";

export class MetakeepSolanaWeb3Client implements SolanaWeb3Client {
  wallet: MetakeepSolanaWalletAdapter;
  sdk: MetakeepSDK.MetaKeep;

  constructor(
    private config: CivicWeb3ClientConfig & {
      metakeep: MetakeepConfig;
      solanaConnection?: Connection;
    },
    private user: User<UserDetails>,
    readonly address: string,
  ) {
    this.sdk = new MetakeepSDK.MetaKeep({
      appId: config.metakeep.solana.publicAppId,
      user: { email: user.email },
      // Default chainId - can be changed
      chainId: config.initialChain?.id,

      rpcNodeUrls: toMetakeepRpcUrls(getEthereumProviderRPCsFromConfig(config)),
    });
    this.wallet = new MetakeepSolanaWalletAdapter(address, this.sdk);
  }

  private async init() {
    await this.wallet.connect();

    // if the config includes a connection, we can expose this wallet as a Solana Wallet Standard wallet
    if (this.config.solanaConnection) {
      // walletStandardAdapter is an implementation of the solana wallet adapter interface,
      // that delegates to the underlying wallet
      const walletStandardAdapter = new MetakeepWalletStandardAdapter(
        this.wallet,
        this.config.solanaConnection,
      );
      singletonSolanaWalletAdapter.setImplementation(walletStandardAdapter);
      await walletStandardAdapter.connect();
    }
  }

  async disconnect(): Promise<void> {
    await this.wallet.disconnect();
    await singletonSolanaWalletAdapter?.getImplementation?.()?.disconnect();
  }

  public static async build(
    config: CivicWeb3ClientConfig & {
      metakeep: MetakeepConfig;
      solanaConnection?: Connection;
    },
    user: User<UserDetails>,
    address: string,
  ): Promise<SolanaWeb3Client> {
    const client = new MetakeepSolanaWeb3Client(config, user, address);
    await client.init();
    return client;
  }
}
