import type { GenericEthereumProvider } from "../../types.js";
import type { EIP1193RequestFn, Prettify } from "viem";
import { mergeObjectArrayWithoutDuplicates } from "../utils.js";
import { logger } from "../logger.js";

/**
 * Caveat type for EIP-2255
 */
type Caveat = {
  type: string;
  value: unknown;
};

/**
 * Requested permissions object shape for EIP-2255
 * e.g. { eth_accounts: {}, foo_bar: { someField: 'someValue' } }
 */
export type RequestedPermissions = {
  [permissionName: string]: Record<string, unknown>;
};

/**
 * Permission type for EIP-2255
 */
export type Permission = {
  parentCapability: string; // e.g. "eth_accounts"
  invoker?: string;
  caveats: Caveat[];
};

/**
 * Define the EIP2255 request interface
 */
type EIP2255Schema = [
  /**
   * @description Returns the current set of permissions granted by the wallet.
   * @example
   * provider.request({ method: 'wallet_getPermissions' })
   * // => [{ parentCapability: 'eth_accounts', ... }, ...]
   */
  {
    Method: "wallet_getPermissions";
    // no parameters
    Parameters: undefined;
    ReturnType: [];
  },
  /**
   * @description Requests new permissions from the wallet.
   * @example
   * provider.request({
   *   method: 'wallet_requestPermissions',
   *   params: [{ eth_accounts: {} }],
   * })
   * // => [{ parentCapability: 'eth_accounts', caveats: { ... } }, ...]
   */
  {
    Method: "wallet_requestPermissions";
    Parameters: RequestedPermissions;
    ReturnType: Permission[];
  },

  // These two are actually ERC-7715, but are used by metamask and therefore expected by viem/wagmi
  {
    Method: "wallet_grantPermissions";
    Parameters: RequestedPermissions;
    ReturnType: Permission[];
  },
  {
    Method: "wallet_revokePermissions";
    Parameters: RequestedPermissions;
    ReturnType: Permission[];
  },
];
// all possible Method values
type EIP2255Method = EIP2255Schema[number]["Method"];

const eip2255Methods: EIP2255Method[] = [
  "wallet_getPermissions" as const,
  "wallet_requestPermissions" as const,
  // ERC-7715
  "wallet_grantPermissions" as const,
  "wallet_revokePermissions" as const,
];

export type EIP2255Provider = Prettify<{
  request: EIP1193RequestFn<EIP2255Schema>;
}>;

export class EIP2255ProviderImpl
  implements GenericEthereumProvider, EIP2255Provider
{
  private permissionsStore: Record<string, Permission> = {};

  // Overloads for EIP-2255 methods
  public async request(args: {
    method: "wallet_getPermissions";
    params?: [];
  }): Promise<Permission[]>;
  public async request(args: {
    method: "wallet_requestPermissions";
    params: [RequestedPermissions];
  }): Promise<Permission[]>;
  // Overloads for ERC-7715 methods
  public async request(args: {
    method: "wallet_grantPermissions";
    params: [RequestedPermissions];
  }): Promise<Permission[]>;
  public async request(args: {
    method: "wallet_revokePermissions";
    params: [RequestedPermissions];
  }): Promise<Permission[]>;

  // Single implementation
  // We define the implementation this way so that the class can implement
  // the EIP2255Provider interface, which
  // requires a generic request method
  public async request(args: {
    method: string;
    params?: unknown[];
  }): Promise<unknown> {
    logger.web3.provider.debug("EIP2255ProviderImpl request args", args);
    const { method, params = [] } = args;

    switch (method) {
      case "wallet_getPermissions": {
        return Object.values(this.permissionsStore);
      }

      case "wallet_requestPermissions":
      case "wallet_grantPermissions": {
        const requested = params[0] as RequestedPermissions;
        for (const key of Object.keys(requested)) {
          // construct the caveats array
          const requestedElement = requested[key];
          const requestedCaveatsArray: Caveat[] = Object.entries(
            requestedElement ?? {},
          ).map(([type, value]) => ({ type, value }));

          // is there already a permission for this key?
          const existingPermission = this.permissionsStore[key];
          if (existingPermission) {
            // if so, update it
            this.permissionsStore[key] = {
              ...existingPermission,
              caveats: mergeObjectArrayWithoutDuplicates(
                existingPermission.caveats,
                requestedCaveatsArray,
                (a, b) => a.type === b.type && a.value === b.value,
              ),
            };
          } else {
            // otherwise, create a new permission
            this.permissionsStore[key] = {
              parentCapability: key,
              caveats: requestedCaveatsArray,
            };
          }
        }
        return Object.values(this.permissionsStore);
      }
      case "wallet_revokePermissions": {
        const requested = params[0] as RequestedPermissions;
        for (const key of Object.keys(requested)) {
          // is there already a permission for this key?
          const existingPermission = this.permissionsStore[key];
          if (existingPermission) {
            // if so, remove it
            delete this.permissionsStore[key];
          }
        }
        return Object.values(this.permissionsStore);
      }
    }

    // If the method is not recognized, throw an error
    throw new Error(`Method not supported: ${method}`);
  }
}

/**
 * Given an eip1193 provider, augment it to support EIP-2255 methods
 * @param underlying - an EIP-1193 provider that has a `request` method
 * @returns a provider that supports EIP-2255 methods alongside EIP-1193 methods
 */
export const wrapWithEIP2255 = <T extends GenericEthereumProvider>(
  underlying: T,
): T & EIP2255Provider => {
  const eip2255Impl = new EIP2255ProviderImpl();

  return new Proxy(underlying as T & EIP2255Provider, {
    get(target, prop, receiver) {
      // If the property is one of the EIP-2255 methods, call eip2255Impl, otherwise call the underlying provider
      if (prop === "request") {
        return function (args: { method: string; params?: unknown[] }) {
          logger.web3.provider.debug("wrapWithEIP2255 proxy args", args);
          const { method, params } = args;

          if (eip2255Methods.includes(method as EIP2255Method)) {
            return eip2255Impl.request({
              method: method as EIP2255Method,
              params,
            } as Parameters<EIP1193RequestFn<EIP2255Schema>>);
          } else {
            // Otherwise pass to underlying
            return target.request(args);
          }
        };
      }

      // Fallback for everything else
      return Reflect.get(target, prop, receiver);
    },

    set() {
      // Setting properties is not allowed
      throw new Error("Unauthorized operation");
    },
  });
};
