import MetakeepSDK from "metakeep";
import type { CivicWeb3ClientConfig, UserDetails } from "../../../types.js";
import {
  type Address,
  createWalletClient,
  custom,
  type WalletClient,
} from "viem";
import type { EthereumWeb3Client } from "../../ethereum/EthereumWeb3Client.js";
import { baseSepolia } from "viem/chains";
import { createTypedProvider } from "../../ethereum/index.js";
import { logger } from "../../logger.js";
import type { TypedEthereumProvider } from "../../ethereum/EIP1193ProviderImpl.js";
import { getEthereumProviderRPCsFromConfig } from "../../walletUtils.js";
import type { User } from "@civic/auth";
import { type MetakeepConfig, toMetakeepRpcUrls } from "../util.js";

export const INITIAL_CHAIN = baseSepolia; // initial chain, can be switched later by the client

/**
 * The Metakeep implementation of the generic Web3Client interface.
 * Web3Client provides all clients (react and non-react) a common interface to
 * web3 wallet functionality, an address and a ViemClient, as well as a function to register a new wallet
 * This class implements this using Metakeep, hiding the details from the client.
 */
export class MetakeepEthereumWeb3Client implements EthereumWeb3Client {
  /**
   * An EIP1193 provider wrapping metakeep
   * Will be null if the user does not yet have a wallet, or if init is not yet called
   */
  provider: TypedEthereumProvider | null;
  /**
   * A viem client based on the above provider
   * Will be null if the user does not yet have a wallet, or if init is not yet called
   */
  client: WalletClient | null;

  sdk: MetakeepSDK.MetaKeep;

  constructor(
    private config: CivicWeb3ClientConfig & { metakeep: MetakeepConfig },
    private user: User<UserDetails>,
    readonly address: Address,
  ) {
    this.sdk = new MetakeepSDK.MetaKeep({
      appId: config.metakeep.ethereum.publicAppId,
      user: { email: user.email },
      // Default chainId - can be changed
      chainId: config.initialChain?.id,

      rpcNodeUrls: toMetakeepRpcUrls(getEthereumProviderRPCsFromConfig(config)),
    });

    // call init() to create the wallet client and provider
    this.client = null;
    this.provider = null;
  }

  private async init() {
    const provider = await this.sdk.ethereum;
    await provider.enable();
    const typedProvider = createTypedProvider(provider);
    this.provider = typedProvider;

    this.client = createWalletClient({
      chain: this.config.initialChain ?? INITIAL_CHAIN,
      transport: custom(typedProvider),
      account: this.address,
    });
  }

  public async disconnect(): Promise<void> {
    logger.web3.metakeep.debug(
      "Metakeep provider disconnecting",
      this.provider,
    );
    this.provider?.disconnect();
  }

  /**
   * Create and initialise a web3 client (which wraps a Viem WalletClient)
   * @param config
   * @param user
   * @param address
   */
  static async build(
    config: CivicWeb3ClientConfig & { metakeep: MetakeepConfig },
    user: User<UserDetails>,
    address: Address,
  ): Promise<EthereumWeb3Client> {
    const client = new MetakeepEthereumWeb3Client(config, user, address);
    await client.init();
    return client;
  }
}
