import {
  type EIP1193EventMap,
  type EIP1193Events,
  type EIP1193Provider,
  ProviderRpcError,
  type SendTransactionParameters,
  type SignableMessage,
  type SignTypedDataParameters,
} from "viem";
import type { GenericEthereumProvider } from "../../types.js";
import EventEmitter from "events";
import { logger } from "../logger.js";
import type { EIP2255Provider } from "./EIP2255Provider.js";
import type { EventRegistrar } from "../lazy/LazyProxy.js";

type SignTypedDataResponse =
  | {
      signature: string;
      r: string;
      s: string;
      v: string;
      status: "SUCCESS";
    }
  | {
      status:
        | "USER_REQUEST_DENIED"
        | "SOMETHING_WENT_WRONG"
        | "INVALID_REQUEST";
    };

export type TypedEthereumProvider = EIP1193Provider &
  DisconnectableProvider &
  EIP2255Provider &
  EventRegistrar<EIP1193EventMap>;

const isEventHandler = (
  underlying: GenericEthereumProvider,
): underlying is EIP1193Events & GenericEthereumProvider => {
  return Object.prototype.hasOwnProperty.call(underlying, "on");
};

// An EIP1193 provider that responds to remote disconnect requests
// (e.g. when disconnect is triggered by an external request rather than internally in the wallet)
export interface DisconnectableProvider {
  disconnect(): void;
}

type EventEmitterEventMap = {
  [K in keyof EIP1193EventMap]: EIP1193EventMap[K] extends (
    ...args: infer P
  ) => void
    ? P
    : never;
};

export class EIP1193ProviderImpl
  implements EIP1193Provider, DisconnectableProvider
{
  private underlying: GenericEthereumProvider; // a request() function
  private localEventEmitter: EventEmitter<EventEmitterEventMap>;

  constructor(provider: GenericEthereumProvider) {
    this.underlying = provider;
    this.localEventEmitter = new EventEmitter<EventEmitterEventMap>();
  }

  // If the underlying provider doesn't handle events,
  // we add an event emitter to handle them.

  /** Overloads for typed methods */
  async request(args: {
    method: "eth_accounts";
    params?: unknown[];
  }): Promise<string[]>;
  async request(args: {
    method: "eth_chainId";
    params?: unknown[];
  }): Promise<string>;
  async request(args: {
    method: "eth_sendTransaction";
    params: [SendTransactionParameters];
  }): Promise<string>;
  async request(args: {
    method: "eth_sign" | "personal_sign";
    params: [SignableMessage];
  }): Promise<string>;
  async request(args: {
    method: "eth_signTypedData" | "eth_signTypedData_v4";
    params: [SignTypedDataParameters];
  }): Promise<string>;

  /** Fallback for any other method */
  async request(args: { method: string; params?: unknown[] }): Promise<unknown>;

  /** Single implementation */
  async request(args: {
    method: string;
    params?: unknown[];
  }): Promise<unknown> {
    // Exception for signTypedData methods where the response is wrapped in an object
    if (
      args.method === "eth_signTypedData" ||
      args.method === "eth_signTypedData_v4"
    ) {
      const structuredResponse: SignTypedDataResponse =
        (await this.underlying.request(args)) as SignTypedDataResponse;
      logger.web3.provider.debug("structuredResponse", structuredResponse);

      if (structuredResponse.status !== "SUCCESS") {
        throw new Error("Metakeep Error: " + structuredResponse.status);
      }
      return structuredResponse.signature;
    }
    logger.web3.provider.debug("EIP1193ProviderImpl: request", args);
    const res = this.underlying.request(args);
    res.then((result) => {
      logger.web3.provider.debug("EIP1193ProviderImpl: request result", result);
    });
    return res;
  }

  disconnect() {
    this.localEventEmitter.emit(
      "disconnect",
      new ProviderRpcError(new Error("Disconnected by user"), {
        shortMessage: "Disconnected by user",
        code: 4900,
      }),
    );
  }

  // EIP-1193 style event handling
  on<Event extends keyof EIP1193EventMap>(
    event: Event,
    listener: EIP1193EventMap[Event],
  ): void {
    // If the `underlying` supports events. Otherwise rely on localEventEmitter.
    if (isEventHandler(this.underlying)) {
      // cast to a typed event handler here - even if the underlying is untyped.
      (this.underlying as unknown as EIP1193Events).on(event, listener);
    } else {
      this.localEventEmitter.on(
        event,
        // the hard cast is needed here to avoid a type error between two ostensibly identical types
        listener as Parameters<typeof this.localEventEmitter.on>[1],
      );
    }
  }

  removeListener<Event extends keyof EIP1193EventMap>(
    event: Event,
    listener: EIP1193EventMap[Event],
  ): void {
    // If the `underlying` supports events. Otherwise rely on localEventEmitter.
    if (isEventHandler(this.underlying)) {
      this.underlying.removeListener(event, listener);
    } else {
      // the hard cast is needed here to avoid a type error between two ostensibly identical types
      this.localEventEmitter.removeListener(
        event,
        listener as Parameters<typeof this.localEventEmitter.removeListener>[1],
      );
    }
  }
}
