import { useUser as useUserInternal } from "@civic/auth/react";
import type { CivicWeb3ClientConfig, UserDetails } from "../../types.js";
import { type Web3Client, web3Events } from "../../lib/Web3Client.js";
import { useEffect, useRef, useState } from "react";
import { useCookie } from "../useCookie.js";
import { logger } from "../../lib/logger.js";
import { MetakeepWeb3Client } from "../../lib/metakeep/MetakeepWeb3Client.js";
import { useConnectionInternal } from "./useConnectionOptional.js";
import { useWalletInternal } from "./useWalletOptional.js";

// This hook returns a web3client object that can be used to interact with the user's wallet.
// Web3Client is a generic interface for embedded wallets provided by civic,
// This hook creates a metakeep version.
export const useWeb3Client = (
  config?: CivicWeb3ClientConfig,
  onSessionEnd?: () => void,
): Web3Client | null => {
  const { user, idToken } = useUserInternal<UserDetails>();
  const [web3Client, setWeb3Client] = useState<Web3Client | null>(null);
  const clientRef = useRef<{ client: Web3Client; id: string } | null>();
  // Track build in progress to avoid creating multiple
  const isBuildingRef = useRef(false);
  const walletCookie = useCookie("civic-embedded-wallet");
  const { connection } = useConnectionInternal();
  const wallet = useWalletInternal();

  useEffect(() => {
    async function loadClient(): Promise<Web3Client | null> {
      if (!user?.email || !idToken) return null;
      const userWithAuth = { ...user, idToken };
      const augmentedConfig = {
        ...(config ?? {}),
        solanaConnection: connection,
      };
      if (connection) {
        console.log(
          "Solana connection found in context - using it to register wallet with the solana wallet standard",
        );
      }

      const onDisconnect = async () => {
        onSessionEnd?.();
        // if using the solana wallet adapter, disconnect the wallet.
        // Note - this has to happen via the walletContext, calling disconnect on the wallet itself will not clear the UI
        await wallet?.disconnect();
      };

      return MetakeepWeb3Client.build(
        augmentedConfig,
        userWithAuth,
        onDisconnect,
      );
    }

    if (
      // prevent building if the client is already built for this user
      (!clientRef?.current?.client ||
        clientRef.current.id !== user?.id ||
        !clientRef.current.client.connected) &&
      // Fields required for embedded wallets
      !!user?.email && // used by Metakeep to identify the user
      !!idToken && // used by the Civic backend to authenticate the user
      !isBuildingRef.current // prevent multiple builds
    ) {
      isBuildingRef.current = true;
      loadClient()
        .then((client) => {
          if (client) {
            logger.web3.metakeep.debug("Web3Client created", client);
            clientRef.current = { client, id: user.id };
            setWeb3Client(client);
            web3Events.emit("web3ClientReady", client);
          }
        })
        .catch((e) => {
          console.error("Failed to build MetakeepWeb3Client", e);
        })
        .finally(() => {
          isBuildingRef.current = false;
        });
    }
    // the user object changes too frequently to be used as a dependency
    // in the useEffect below, so we use the user.id which is guaranteed to be unique for the user
  }, [user?.id, JSON.stringify(config), walletCookie]);

  return web3Client;
};
