import { logger } from "../logger.js";

export type LazyProxy<T extends object> = T &
  ProxyHandler<T> & {
    setImplementation(impl: T): void;
    clearImplementation(): void;
    getImplementation(): T | null;
    ready(): boolean;
  };

// In order to satisfy types with Viem's EventMap, we need to use any[] as the type for the listener
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EventMap = Record<string, (...args: any[]) => void>;
/**
 * A utility type to represent an EventRegistrar with typed event handling.
 */
export type EventRegistrar<TEventMap extends EventMap> = {
  on<TEvent extends keyof TEventMap>(
    event: TEvent,
    listener: TEventMap[TEvent],
  ): void;
  removeListener<TEvent extends keyof TEventMap>(
    event: TEvent,
    listener: TEventMap[TEvent],
  ): void;
};

/**
 * Creates a lazy proxy that defers method/property access to the underlying implementation
 * until it's set. The proxy also handles event registration, storing listeners before the
 * implementation is set, and forwarding them once it becomes available.
 *
 * @param knownMethods - An optional list of methods to "recognize" for runtime duck typing.
 *   If specified, the proxy will return dummy functions for these methods until the implementation
 *   is set, allowing `if (proxy.someMethod)` checks to work. Defaults to an empty array.
 *
 * @returns A proxy object that combines the laziness and event handling of the underlying type.
 */
export const createLazyProxy = <
  TUnderlying extends EventRegistrar<TEventMap>,
  TEventMap extends EventMap,
>(
  knownMethods: (keyof TUnderlying)[] = [],
): LazyProxy<TUnderlying> => {
  let realImpl: TUnderlying | null = null;

  // Lightweight target object: purely a placeholder for the proxy
  const target: Record<string, unknown> = {};

  // Listener store: Stores events and listeners before the implementation is ready
  const listenerStore: Map<keyof TEventMap, TEventMap[keyof TEventMap][]> =
    new Map();

  const proxy = new Proxy(target as TUnderlying, {
    get(_target, prop, receiver) {
      // if the prop was added to the target, return it
      if (prop in target) return Reflect.get(target, prop, receiver);

      // Handle internal methods
      if (prop === "setImplementation") {
        return (impl: TUnderlying) => {
          realImpl = impl;

          // Forward all stored listeners to the real implementation
          listenerStore.forEach((listeners, event) => {
            listeners.forEach((listener) => {
              logger.web3.provider.debug(
                "LazyProxy: Registering stored listener for",
                event,
              );
              impl.on(event, listener); // Delegate to real implementation
            });
          });
          listenerStore.clear(); // Clear the listener store to avoid duplicates
        };
      } else if (prop === "clearImplementation") {
        return () => {
          realImpl = null;
        };
      } else if (prop === "getImplementation") {
        return () => realImpl;
      } else if (prop === "ready") {
        return () => !!realImpl; // Return whether the implementation is set
      }

      // Handle event methods: on and removeListener
      if (prop === "on") {
        return <Event extends keyof TEventMap>(
          event: Event,
          listener: TEventMap[Event],
        ) => {
          if (realImpl) {
            logger.web3.provider.debug(
              "LazyProxy: Registering listener for",
              event,
              "on the real impl",
            );
            realImpl.on(event, listener); // Forward to real implementation
          } else {
            logger.web3.provider.debug(
              "LazyProxy: Storing the listener for",
              event,
              " to be registered later on the real impl",
            );
            // Store the listener if implementation is not ready
            if (!listenerStore.has(event)) {
              listenerStore.set(event, []);
            }
            listenerStore.get(event)!.push(listener);
          }
        };
      }

      if (prop === "removeListener") {
        return <Event extends keyof TEventMap>(
          event: Event,
          listener: TEventMap[Event],
        ) => {
          if (realImpl) {
            realImpl.removeListener(event, listener); // Forward to real implementation
          } else {
            // Remove the listener from the store
            const listeners = listenerStore.get(event);
            if (listeners) {
              const index = listeners.indexOf(listener);
              if (index !== -1) listeners.splice(index, 1);
              if (listeners.length === 0) listenerStore.delete(event);
            }
          }
        };
      }

      // Handle all other properties/methods
      if (realImpl) {
        return Reflect.get(realImpl, prop, receiver); // Forward to real implementation
      }

      if (knownMethods.includes(prop as keyof TUnderlying)) {
        // Provide a dummy function for known methods to enable duck typing
        return () => {
          throw new Error(
            `Lazy proxy: Method "${String(prop)}" cannot be called because the implementation is not set yet.`,
          );
        };
      }

      // Default behavior: Return undefined for unknown properties
      return undefined;
    },

    set(_target, prop, value) {
      if (prop === "setImplementation" || prop === "ready") {
        Reflect.set(target, prop, value); // Allow defining internal methods
        return true;
      }

      if (!realImpl) {
        // implementation is not set yet, add this to the lightweight target
        return Reflect.set(target, prop, value);
      }

      return Reflect.set(realImpl, prop, value); // Forward to real implementation
    },
  }) as LazyProxy<TUnderlying>;

  return proxy;
};
