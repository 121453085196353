import type { EthereumWeb3Client } from "./ethereum/EthereumWeb3Client.js";
import type { SolanaWeb3Client } from "./solana/SolanaWeb3Client.js";
import type { Wallets } from "../types.js";
import { EventEmitter } from "eventemitter3";

export interface Web3Client {
  ethereum: EthereumWeb3Client;
  solana: SolanaWeb3Client;

  connected: boolean;

  createWallets(): Promise<Wallets | null>;
  disconnect(): Promise<void>;
}

type Web3Events = {
  web3ClientReady: (client: Web3Client) => void;
};
export const web3Events = new EventEmitter<Web3Events>();
