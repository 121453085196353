// --------------------------------------------------------------
// Fallback that returns a dummy wallet hook, used if the solana wallet adapter is not present
// --------------------------------------------------------------

import type { WalletContextState } from "@solana/wallet-adapter-react";

const notFoundFunction = async () => {
  throw new Error("Wallet not found");
};
const noopPromise = async () => {};

export const walletFallback = {
  wallet: null,
  autoConnect: false,
  connected: false,
  publicKey: null,
  wallets: [],
  connecting: false,
  disconnecting: false,
  sendTransaction: notFoundFunction,
  signTransaction: notFoundFunction,
  signAllTransactions: notFoundFunction,
  signMessage: notFoundFunction,
  signIn: notFoundFunction,
  select: () => {},
  connect: noopPromise,
  disconnect: noopPromise,
};
export const useWalletInternal: () => WalletContextState = () => walletFallback;
