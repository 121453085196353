import { injected } from "@wagmi/connectors";
import type { GenericEthereumProvider } from "../../types.js";
import { createConnector, type CreateConnectorFn } from "wagmi";
import type { EIP1193Provider } from "viem";
import { singletonEthereumProvider } from "../../lib/ethereum/index.js";
import { logger } from "../../lib/logger.js";
import { web3Events } from "../../lib/Web3Client.js";
import type { TypedEthereumProvider } from "../../lib/ethereum/EIP1193ProviderImpl.js";
import { WALLET_LOGO } from "../../lib/constants.js";

export type CreateLazyConnectorProperties = {
  provider: TypedEthereumProvider | null;
  setProvider: (newProvider: TypedEthereumProvider) => Promise<void>;
  ready: () => boolean;
};
export type CreateLazyConnectorFn = CreateConnectorFn<
  GenericEthereumProvider,
  CreateLazyConnectorProperties
> & { provider: EIP1193Provider | null };

type ConnectorConfig = {
  debug?: boolean;
};
const defaultConfig: Required<ConnectorConfig> = {
  debug: false,
};

/**
 * Wagmi connector implementation, that takes a lazy EIP1193 provider, that can be instantiated later, and turns it into a wagmi connector
 */
export function LazyWagmiConnector(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _config: ConnectorConfig,
): CreateConnectorFn {
  logger.web3.wagmi.debug("LazyWagmiConnector: Creating connector");

  // use a lazy ethereum 1193 provider that will be passed into the connector.
  // this provider will be populated later once the user has logged in, via the setProvider method
  // that is added to the standard wagmi connector interface.
  const provider = singletonEthereumProvider;

  // create a wagmi connector function that wraps the 1193 provider,
  // providing metadata, and linking wagmi functions to eip1193 functions
  const connectorFn = injected({
    shimDisconnect: true,
    target: {
      id: "civic",
      name: "Civic Wallet",
      icon: WALLET_LOGO,
      provider,
    },
  }) as unknown as CreateLazyConnectorFn;

  // augment the connector function with custom functions to allow injection of the provider later
  return createConnector(
    (createConnectorConfig: Parameters<CreateConnectorFn>[0]) => {
      const connector = connectorFn(createConnectorConfig);
      connector.setProvider = async (newProvider: TypedEthereumProvider) => {
        logger.web3.wagmi.debug("LazyWagmiConnector: Setting provider");
        provider.setImplementation(newProvider);

        // notify anything listening that it should update its accounts
        const accounts = await newProvider.request({ method: "eth_accounts" });
        logger.web3.wagmi.debug(
          "LazyWagmiConnector: Setting provider accounts",
          accounts,
        );
        createConnectorConfig.emitter.emit("change", {
          accounts,
        });

        // Wagmi events may continue to query the provider after it has disconnected
        // Since the user has just logged out, but the provider may still be active
        // this fools wagmi into thinking that the wallet is still active.
        // Therefore, removing the underlying provider from the proxy simulates,
        // in a not particularly elegant way, the wallet being closed.
        // There may be a better way to do this.
        provider.on("disconnect", () => {
          logger.web3.wagmi.debug(
            "LazyWagmiConnector: Provider disconnected - clearing the implementation",
          );
          provider.clearImplementation();
        });
      };

      // Listen for web3Client readiness
      web3Events.on("web3ClientReady", (web3Client) => {
        if (
          web3Client?.ethereum.provider &&
          typeof connector.setProvider === "function"
        ) {
          connector.setProvider(web3Client.ethereum.provider);
          web3Client.ethereum.provider.on("disconnect", () => {
            connector.disconnect();
          });
        }
      });

      connector.ready = () => provider.ready();

      return connector;
    },
  );
}

LazyWagmiConnector.type = "civic" as const;

export const embeddedWallet = (config: ConnectorConfig = {}) =>
  LazyWagmiConnector({
    ...defaultConfig,
    ...config,
  });
