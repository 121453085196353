import type { EVMChainRpcUrls } from "../../types.js";
import type { WalletResponse } from "./civicApiClient.js";

/**
 * Given a set of RPC urls, extract the first http url for each chain
 * @param chainRpcUrls
 */
export const toMetakeepRpcUrls = (
  chainRpcUrls: EVMChainRpcUrls,
): Record<number, string> => {
  const rpcUrls: Record<number, string> = {};
  for (const [chainId, urls] of Object.entries(chainRpcUrls)) {
    rpcUrls[parseInt(chainId)] = urls.http[0];
  }
  return rpcUrls;
};

export type MetakeepConfig = WalletResponse["config"]["metakeep"];
