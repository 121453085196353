"use client";
import {
  type AuthContextType,
  useToken,
  useUser as useUserInternal,
} from "@civic/auth/react";
import React, {
  createContext,
  type FC,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useCreateWallets } from "../hooks/useCreateWallets.js";
import { AuthStatus, type DisplayMode } from "@civic/auth";
import {
  type ExistingWeb3UserContext,
  type NewWeb3UserContext,
  type Web3ProviderProps,
  type Web3UserContextType,
} from "../types.js";
import { useWeb3Client } from "../hooks/metakeep/useWeb3Client.js";
import { useTriggerSigninOnWalletConnection } from "../hooks/useTriggerSigninOnWalletConnection.js";

// The Web3UserProvider component is a wrapper around the CivicAuthProvider component that provides
// an augmented user object to the rest of the application, with web3 wallet information.
// If the user already has an embedded wallet, the wallet address and viem client are added to the user object.
// NOTE: When using wagmi, these augmented user fields are not necessary, as the wagmi connector
// provides the same information and can be directly injected into the wagmi config
// If the user does not yet have an embedded wallet, a createWallet function is added to the user object.
const defaultContext = {
  displayMode: "iframe" as DisplayMode,
  isAuthenticated: false,
  isLoading: false,
  error: null,
  isSigningOut: false,
  signIn: () => {
    throw new Error("signIn not implemented");
  },
  signOut: () => {
    throw new Error("signOut not implemented");
  },
  user: null,
  walletAddress: null,
  wallet: null,
  createWallet: () => {
    throw new Error("createWallet not implemented");
  },
  walletCreationInProgress: false,
  authStatus: AuthStatus.UNAUTHENTICATED,
} as Web3UserContextType;
const Web3UserContext = createContext<Web3UserContextType>(defaultContext);
export const useUser = () => useContext(Web3UserContext);

type InternalWeb3ProviderProps = {
  registerLogoutCallback: (callback: () => Promise<void>) => void;
};

export const Web3UserProvider: FC<
  PropsWithChildren & Web3ProviderProps & InternalWeb3ProviderProps
> = ({ children, registerLogoutCallback, ...props }) => {
  const internalUserContext = useUserInternal();
  const tokens = useToken();
  // we need to sign out of the civic auth provider when the user signs out of the web3 wallet
  // however, as the user can also be signed out via the civic auth provider, we don't
  // want to create a loop here, so we make sure the user is authenticated before trying
  const doSignOut = useCallback(() => {
    if (internalUserContext.authStatus !== AuthStatus.AUTHENTICATED) {
      return;
    }
    return internalUserContext.signOut();
  }, [internalUserContext.authStatus]);
  const web3Client = useWeb3Client(props, doSignOut);

  // if a wallet connector UI is being used, this will trigger a sign in when the user selects the civic wallets
  useTriggerSigninOnWalletConnection();

  const { createWallet, walletCreationInProgress } =
    useCreateWallets(web3Client);

  // register the logout callback
  useEffect(() => {
    if (web3Client) {
      registerLogoutCallback(() => web3Client.disconnect());
    }
  }, [web3Client, registerLogoutCallback]);

  const web3User: Web3UserContextType = useMemo(() => {
    const isAuthenticated = !!internalUserContext.user;
    const authContext: AuthContextType = {
      signIn: internalUserContext.signIn,
      authStatus: internalUserContext.authStatus,
      isAuthenticated,
      isLoading: internalUserContext.isLoading,
      error: internalUserContext.error,
      signOut: internalUserContext.signOut,
      displayMode: internalUserContext.displayMode,
    };

    if (!web3Client) {
      return {
        ...authContext,
        ...tokens,
        // we override the civic auth isLoading state as we're still loading until we have a web3 client
        isLoading: !!internalUserContext?.user,
        user: null,
        createWallet,
        walletCreationInProgress,
      } as NewWeb3UserContext;
    }

    // we don't have an address so we're a new user and need to expose the createWallet function
    if (!web3Client.ethereum?.address && !web3Client.solana?.address) {
      return {
        ...authContext,
        ...tokens,
        isLoading: false,
        createWallet,
        user: internalUserContext.user,
        walletCreationInProgress,
      } as NewWeb3UserContext;
    }

    // this is an existing user with a wallet
    return {
      ...authContext,
      ...tokens,
      isLoading: false,
      ethereum: {
        address: web3Client.ethereum.address,
        wallet: web3Client.ethereum.client,
      },
      solana: {
        address: web3Client.solana.address,
        wallet: web3Client.solana.wallet,
      },
      user: internalUserContext.user,
      walletCreationInProgress,
    } as ExistingWeb3UserContext;
  }, [
    internalUserContext.user,
    createWallet,
    web3Client?.ethereum.address,
    web3Client?.solana.address,
  ]);

  return (
    <Web3UserContext.Provider value={web3User}>
      {children}
    </Web3UserContext.Provider>
  );
};
