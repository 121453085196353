import { useEffect } from "react";
import { useUser as useUserInternal } from "@civic/auth/react";
import { singletonSolanaWalletAdapter } from "../lib/solana/index.js";

/**
 * This hook registers the Civic signIn function with the wallet adapter.
 * It allows the wallet adapter to trigger a sign-in when needed, such as when
 * a user clicks "connect" in a wallet UI before they've authenticated.
 */
export const useTriggerSigninOnWalletConnection = () => {
  const internalUserContext = useUserInternal();

  useEffect(() => {
    singletonSolanaWalletAdapter.registerSignInCallback(() =>
      internalUserContext.signIn(),
    );
  }, [internalUserContext.signIn]);
};
