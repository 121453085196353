// A type representing an object that can trigger the sign-in process
// This is added to the CivicWallet interface to allow registering a
// callback that opens the Civic Auth login window on connect.
import { web3Events } from "../Web3Client.js";
import { PublicKey } from "@solana/web3.js";
import { createLazyProxy, type LazyProxy } from "../lazy/LazyProxy.js";
import type { CivicWallet } from "./walletAdapter/window.js";

export type SignInTrigger = {
  registerSignInCallback: (callback: () => Promise<void>) => void;
};
// The same as CivicWalletEvent, but using a 'Type' so that typescript automatically
// interprets it as inheriting Record<string, unknown> instead of a specific type.
export type CivicWalletEventType = {
  connect(...args: unknown[]): unknown;
  disconnect(...args: unknown[]): unknown;
  accountChanged(...args: unknown[]): unknown;
};
// Type definitions for the connect result and promise handling
type ConnectResult = { publicKey: PublicKey };
type ConnectPromise = {
  resolve: (value: ConnectResult | PromiseLike<ConnectResult>) => void;
  reject: (reason?: Error) => void;
};
export const createLazySolanaWalletAdapter = (): LazyProxy<CivicWallet> &
  SignInTrigger => {
  // Queue of promises waiting to be resolved when the wallet connects
  const connectPromises: ConnectPromise[] = [];

  // Callback that will be called to trigger sign-in when needed
  let signInCallback: (() => Promise<void>) | null = null;

  // Flag to prevent setting up multiple event listeners
  let hasSetupEventListener = false;

  // Flag to track if a sign-in operation is currently in progress
  let isConnecting = false;

  // Sets up the event listener for web3ClientReady event
  // This happens only once to prevent duplicate listeners
  const setupEventListener = () => {
    // Skip if already set up
    if (hasSetupEventListener) return;
    hasSetupEventListener = true;

    // Listen for the web3ClientReady event which fires when the wallet is initialized
    web3Events.on("web3ClientReady", async (web3Client) => {
      try {
        if (proxy.ready()) {
          // CASE 1: The wallet is ready (implementation exists)
          // Call connect on the underlying implementation to ensure it's connected
          await web3Client.solana.wallet.connect();

          // Get the wallet's public key
          const publicKey = new PublicKey(web3Client.solana.address);

          // Resolve all waiting promises with the wallet's public key
          const result = { publicKey };
          while (connectPromises.length > 0) {
            const promise = connectPromises.shift();
            if (promise) promise.resolve(result);
          }
          // Reset connecting flag since we're done
          isConnecting = false;
        } else {
          // CASE 2: The wallet is not ready, but we have pending connect promises
          // This means someone called connect() before the wallet was ready
          if (connectPromises.length > 0 && signInCallback) {
            // Set connecting flag to prevent duplicate sign-in attempts
            isConnecting = true;
            // Trigger the sign-in flow
            await signInCallback();
            // We don't resolve promises here - wait for next web3ClientReady event
            // that will happen after sign-in completes and the wallet is initialized
          }
        }
      } catch (error) {
        // Error during connection process - reject all pending promises
        while (connectPromises.length > 0) {
          // remove the promise from the queue
          const promise = connectPromises.shift();
          if (promise)
            promise.reject(
              error instanceof Error ? error : new Error(String(error)),
            );
        }
        isConnecting = false;
      }
    });
  };

  // Create the lazy proxy instance implementing both the CivicWallet and SignInTrigger interfaces
  const proxy: LazyProxy<CivicWallet> & SignInTrigger = createLazyProxy<
    CivicWallet,
    CivicWalletEventType
  >(["connect"]) as LazyProxy<CivicWallet> & SignInTrigger;

  // Add a connect method to the proxy
  // This will be called before the real wallet implementation exists
  proxy["connect"] = async (args) => {
    // Make sure our event listener is set up
    setupEventListener();

    // CASE 1: If the real wallet implementation exists, delegate to it
    if (proxy.ready()) {
      const underlying = proxy.getImplementation();
      if (underlying) {
        return underlying.connect(args);
      }
    }

    // CASE 2: A sign-in is already in progress, just wait for it to complete
    if (isConnecting) {
      return new Promise<ConnectResult>((resolve, reject) => {
        connectPromises.push({ resolve, reject });
      });
    }

    // CASE 3: Need to start a new sign-in flow
    if (signInCallback) {
      isConnecting = true;
      try {
        // Trigger the sign-in process
        await signInCallback();
        // Return a promise that will be resolved when web3ClientReady fires
        // after the sign-in completes
        return new Promise<ConnectResult>((resolve, reject) => {
          connectPromises.push({ resolve, reject });
        });
      } catch (error) {
        // If sign-in fails, reset connecting flag and propagate error
        isConnecting = false;
        throw error;
      }
    } else {
      // CASE 4: No sign-in callback registered yet, just queue the promise
      // This handles the race condition where connect is called before
      // registerSignInCallback
      return new Promise<ConnectResult>((resolve, reject) => {
        connectPromises.push({ resolve, reject });
      });
    }
  };

  // Add method to register the sign-in callback
  // This will be called from the React layer to provide the signIn function
  proxy["registerSignInCallback"] = (callback) => {
    signInCallback = callback;
    setupEventListener(); // Ensure event listener is set up

    // If there are pending connect calls and no sign-in is in progress,
    // we should trigger the sign-in now
    if (connectPromises.length > 0 && !isConnecting && !proxy.ready()) {
      isConnecting = true;
      // Call the callback directly - it's already async so it won't block
      callback().catch((error) => {
        // Sign-in failed - reject all pending promises
        isConnecting = false;
        while (connectPromises.length > 0) {
          const promise = connectPromises.shift();
          if (promise) {
            promise.reject(
              error instanceof Error ? error : new Error(String(error)),
            );
          }
        }
      });
    }
  };

  return proxy;
};
