import type {
  PublicKey,
  SendOptions,
  Transaction,
  TransactionSignature,
  VersionedTransaction,
  Connection,
} from "@solana/web3.js";
import type {
  SolanaSignInInput,
  SolanaSignInOutput,
} from "@solana/wallet-standard-features";
import type { CivicWallet, CivicWalletEvent } from "./window.js";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { Transaction as LegacyTransaction } from "@solana/web3.js";
import type { MetakeepSolanaWalletAdapter } from "../../metakeep/solana/MetakeepSolanaWalletAdapter.js";

/**
 * MetakeepWalletStandardAdapter implements the Solana Wallet Standard interface,
 * by delegating most functionality to an underlying MetakeepSolanaWalletAdapter instance,
 * with appropriate adaptations.
 *
 * It accepts a Solana Connection to support sending transactions.
 */
export class MetakeepWalletStandardAdapter implements CivicWallet {
  /**
   * Constructor takes a delegate wallet adapter and a Solana connection.
   * @param delegate - The underlying MetakeepSolanaWalletAdapter instance.
   * @param connection - A Solana Connection instance used for sending transactions.
   */
  constructor(
    private delegate: MetakeepSolanaWalletAdapter,
    private connection: Connection,
  ) {}

  /**
   * Getter for the publicKey property.
   * Returns the delegate's publicKey if connected, otherwise null.
   */
  get publicKey(): PublicKey | null {
    return this.delegate.connected ? this.delegate.publicKey : null;
  }

  /**
   * Connects the wallet.
   * Delegates the connection process to the underlying adapter.
   * After connecting, returns an object containing the publicKey.
   *
   * @param options - Optional connection options.
   * @returns A promise resolving to an object containing the publicKey.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async connect(options?: {
    onlyIfTrusted?: boolean;
  }): Promise<{ publicKey: PublicKey }> {
    // Await the delegate's connect method.
    await this.delegate.connect();
    // Return the publicKey wrapped in an object.
    return { publicKey: this.delegate.publicKey };
  }

  /**
   * Disconnects the wallet.
   * Simply delegates to the underlying adapter's disconnect method.
   */
  async disconnect(): Promise<void> {
    await this.delegate.disconnect();
  }

  /**
   * Signs and sends a transaction.
   * First, the transaction is signed using the delegate's signTransaction method.
   * Then, the signed transaction is sent via the provided Solana connection.
   *
   * Handles both legacy and versioned transactions.
   *
   * @param transaction - The transaction to sign and send.
   * @param options - Optional send options.
   * @returns A promise resolving to an object containing the transaction signature.
   */
  async signAndSendTransaction<T extends Transaction | VersionedTransaction>(
    transaction: T,
    options?: SendOptions,
  ): Promise<{ signature: TransactionSignature }> {
    // Ensure the wallet is connected.
    if (!this.delegate.connected) throw new WalletNotConnectedError();

    // Delegate the signing process.
    const signedTx = await this.delegate.signTransaction(transaction);

    let signature: TransactionSignature;

    // Check the type of the transaction to handle sending appropriately.
    if (signedTx instanceof LegacyTransaction) {
      // For legacy Transactions, assume no extra signers are needed.
      signature = await this.connection.sendTransaction(signedTx, [], options);
    } else {
      // For VersionedTransactions, any extra signatures are applied directly to the transaction beforehand
      signature = await this.connection.sendTransaction(signedTx, options);
    }

    // Return the signature wrapped in an object.
    return { signature };
  }

  /**
   * Signs a transaction.
   * Simply delegates the signing process to the underlying adapter.
   *
   * @param transaction - The transaction to sign.
   * @returns A promise resolving to the signed transaction.
   */
  async signTransaction<T extends Transaction | VersionedTransaction>(
    transaction: T,
  ): Promise<T> {
    return this.delegate.signTransaction(transaction);
  }

  /**
   * Signs all transactions in an array.
   * Delegates each signing to the underlying adapter.
   *
   * @param transactions - An array of transactions to sign.
   * @returns A promise resolving to an array of signed transactions.
   */
  async signAllTransactions<T extends Transaction | VersionedTransaction>(
    transactions: T[],
  ): Promise<T[]> {
    return Promise.all(
      transactions.map((tx) => this.delegate.signTransaction(tx)),
    );
  }

  /**
   * Signs an arbitrary message.
   * Delegates the signing to the underlying adapter and wraps the result.
   *
   * @param message - The message to sign as a Uint8Array.
   * @returns A promise resolving to an object containing the signature.
   */
  async signMessage(message: Uint8Array): Promise<{ signature: Uint8Array }> {
    const signature = await this.delegate.signMessage(message);
    return { signature };
  }

  /**
   * Performs a sign-in operation.
   * Delegates the operation to the underlying adapter.
   *
   * @param input - Optional sign-in input parameters.
   * @returns A promise resolving to the sign-in output.
   */
  async signIn(input?: SolanaSignInInput): Promise<SolanaSignInOutput> {
    return this.delegate.signIn(input);
  }

  /**
   * Registers an event listener.
   *
   * For the 'connect' and 'disconnect' events, the listener is forwarded to the delegate.
   * For the 'accountChanged' event, this method does nothing because the account never changes
   * in this implementation.
   *
   * @param event - The name of the event to listen for.
   * @param listener - The callback function to invoke when the event is emitted.
   * @param context - Optional context for the listener.
   */
  on<E extends keyof CivicWalletEvent>(
    event: E,
    listener: CivicWalletEvent[E],
    context?: unknown,
  ): void {
    if (event === "accountChanged") {
      // Account never changes in this implementation.
      // So, registering a listener for 'accountChanged' is a no-op.
      console.debug(
        `Listener for '${event}' registered, but will never be called because the account is immutable in this implementation.`,
      );
    } else {
      // For 'connect' and 'disconnect', forward the event registration to the delegate.
      // Type assertion is used here because the delegate's event keys differ slightly.
      this.delegate.on(event, listener, context);
    }
  }

  /**
   * Unregisters an event listener.
   *
   * For the 'connect' and 'disconnect' events, the listener is removed from the delegate.
   * For the 'accountChanged' event, this method does nothing because it was a no-op.
   *
   * @param event - The name of the event.
   * @param listener - The listener function to remove.
   * @param context - Optional context for the listener.
   */
  off<E extends keyof CivicWalletEvent>(
    event: E,
    listener: CivicWalletEvent[E],
    context?: unknown,
  ): void {
    if (event === "accountChanged") {
      // No-op for 'accountChanged' as it never fires.
      console.debug(
        `Listener for '${event}' removed, though it was a no-op because the account never changes.`,
      );
    } else {
      // Forward removal for 'connect' and 'disconnect' to the delegate.
      this.delegate.off(event, listener, context);
    }
  }

  removeListener<E extends keyof CivicWalletEvent>(
    event: E,
    listener: CivicWalletEvent[E],
    context?: unknown,
  ) {
    return this.off(event, listener, context);
  }
}
