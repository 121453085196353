import type {
  CivicWeb3ClientConfig,
  UserDetailsWithAuth,
} from "../../types.js";
import type { User } from "@civic/auth";
import { env } from "../utils.js";

const DEFAULT_BASE_API =
  env.NEXT_PUBLIC_WALLET_API_BASE_URL || "https://api.civic.com/metakeep";

// configuration required to set up the wallet client
export type ConfigResponse = {
  config: {
    metakeep: {
      solana: {
        publicAppId: string;
      };
      ethereum: {
        publicAppId: string;
      };
    };
  };
};

export type WalletResponse = {
  wallet: {
    ethAddress: string;
    solAddress: string;
  };
} & ConfigResponse; // for optimisation, the wallet get and create calls also return the config

export class CivicMetakeepApiClient {
  // The configuration for the client, including the base URL for the wallet API (defaulted if not provided)
  readonly config: CivicWeb3ClientConfig & { endpoints: { wallet: string } };

  constructor(
    readonly user: User<UserDetailsWithAuth>,
    config: Partial<CivicWeb3ClientConfig> = {},
  ) {
    this.config = {
      ...config,
      endpoints: {
        wallet: DEFAULT_BASE_API,
        ...config?.endpoints,
      },
    };
  }

  // An authenticated request to the wallet service to get the configuration
  async getConfig(): Promise<ConfigResponse> {
    const url = `${this.config.endpoints.wallet}/config`;
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${this.user.idToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Failed to get config: ${response.statusText}`);
    }
    return response.json();
  }

  async getWallet(): Promise<WalletResponse> {
    const url = `${this.config.endpoints.wallet}/wallet`;
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${this.user.idToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Failed to get wallet: ${response.statusText}`);
    }
    return response.json();
  }

  async createWallet(): Promise<WalletResponse> {
    const url = `${this.config.endpoints.wallet}/wallet`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.user.idToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Failed to create wallet: ${response.statusText}`);
    }
    return response.json();
  }
}
