import type { Connector } from "wagmi";
import type { CreateLazyConnectorProperties } from "./LazyWagmiConnector.js";

/**
 * Handles the fact, that Wagmi Connectors do not have a "ready" property.
 *
 * @param connector
 */
export const isReady = (
  connector: Connector,
): connector is Connector & CreateLazyConnectorProperties =>
  Object.prototype.hasOwnProperty.call(connector, "ready")
    ? (connector as Connector & CreateLazyConnectorProperties).ready()
    : true;
