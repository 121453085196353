"use client";
import { type Connector, useAccount, useConnect } from "wagmi";
import { useEffect, useState } from "react";
import { userHasWallet } from "../../lib/walletUtils.js";
import { useUser } from "../../components/Web3UserProvider.js";
import { LazyWagmiConnector } from "../lib/LazyWagmiConnector.js";
import { isReady } from "../lib/utils.js";

const registerConnectorEvents = (
  connector: Connector | undefined,
  onEvent: (...args: unknown[]) => void,
) => {
  if (connector) {
    connector.emitter.on("connect", onEvent);
    connector.emitter.on("change", onEvent);
  }
};

export const useAutoConnect = (
  { autoConnectEmbeddedWallet, autoCreateWallet } = {
    autoConnectEmbeddedWallet: true,
    autoCreateWallet: true,
  },
) => {
  const { status, connectors, connect } = useConnect();
  const userContext = useUser();
  const { isConnected } = useAccount();
  const [connectionInProgress, setConnectionInProgress] =
    useState<boolean>(false);

  const connector = connectors.find(
    (connector) => connector.id === LazyWagmiConnector.type,
  );

  const readyToConnect =
    !!connector &&
    autoConnectEmbeddedWallet &&
    !isConnected &&
    !connectionInProgress;

  const attemptConnect = () => {
    if (readyToConnect && isReady(connector)) {
      setConnectionInProgress(true);
      connect(
        { connector },
        {
          onSettled: () => {
            setConnectionInProgress(false);
          },
        },
      );
    }
  };

  useEffect(
    () => registerConnectorEvents(connector, attemptConnect),
    [connector],
  );
  useEffect(attemptConnect, [connector, readyToConnect]);

  useEffect(() => {
    if (
      !autoConnectEmbeddedWallet ||
      !autoCreateWallet ||
      userContext.walletCreationInProgress
    ) {
      return;
    }
    if (userContext.user && !userHasWallet(userContext)) {
      userContext.createWallet();
    }
  }, [userContext.user, status]);

  return { civicConnector: connector };
};
