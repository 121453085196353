/**
 * Merges two objects, using values from the override object only when they are not null or undefined.
 * Values from the override object will replace values from the default object only if they exist and are not null.
 *
 * @param defaultObj The default object to use as a base
 * @param overrideObj The object containing override values
 * @returns A new object with merged values
 */
export const mergeWithDefault = <T extends object>(
  defaultObj: T,
  overrideObj?: Partial<T>,
): T => {
  if (!overrideObj) return { ...defaultObj };

  return {
    ...defaultObj,
    ...(Object.fromEntries(
      Object.entries(overrideObj).filter(([, value]) => value != null),
    ) as Partial<T>),
  };
};

export const mergeObjectArrayWithoutDuplicates = <T extends object>(
  arr1: T[],
  arr2: T[],
  eq: (a: T, b: T) => boolean = (a, b) => a === b,
): T[] => {
  const merged = [...arr1];
  for (const obj of arr2) {
    if (!merged.some((existing) => eq(existing, obj))) {
      merged.push(obj);
    }
  }
  return merged;
};

// Ensure the sdk works in browser environments where `process` does not exist (e.g. vite apps)
export const env =
  typeof process !== "undefined"
    ? {
        NEXT_PUBLIC_WALLET_API_BASE_URL:
          process.env.NEXT_PUBLIC_WALLET_API_BASE_URL,
      }
    : {};

export const errorMessage = (error: unknown): string | undefined => {
  if (error instanceof Error) {
    return error.message;
  }
  if (typeof error === "string") {
    return error;
  }
  return undefined;
};

export const getCookie = (name: string): string | null => {
  const doc =
    typeof globalThis.document === "undefined" ? null : globalThis.document;
  if (!doc) return null;
  const value = `; ${doc.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length !== 2) return null;
  return parts.pop()?.split(";").shift() ?? null;
};
