import {
  BaseMessageSignerWalletAdapter,
  type SignInMessageSignerWalletAdapter,
  type SupportedTransactionVersions,
  type TransactionOrVersionedTransaction,
  WalletError,
  WalletLoadError,
  type WalletName,
  WalletNotConnectedError,
  WalletNotReadyError,
  WalletReadyState,
  WalletSignTransactionError,
} from "@solana/wallet-adapter-base";
import { PublicKey, type TransactionVersion } from "@solana/web3.js";
import MetakeepSDK from "metakeep";
import { WALLET_LOGO } from "../../constants.js";
import { errorMessage } from "../../utils.js";
import type {
  SolanaSignInInput,
  SolanaSignInOutput,
} from "@solana/wallet-standard-features";

export const MetaKeepWalletName = "Civic" as WalletName<"Civic">;

export class MetakeepSolanaWalletAdapter
  extends BaseMessageSignerWalletAdapter
  implements SignInMessageSignerWalletAdapter
{
  name = MetaKeepWalletName;
  url = "https://auth.civic.com";
  icon = WALLET_LOGO;
  readonly publicKey: PublicKey;
  connecting: boolean;
  _connected: boolean;
  readyState: WalletReadyState =
    typeof globalThis.window === "undefined" ||
    typeof globalThis.document === "undefined"
      ? WalletReadyState.Unsupported
      : WalletReadyState.Loadable;

  constructor(
    address: string,
    private sdk: MetakeepSDK.MetaKeep,
  ) {
    super();
    this.publicKey = new PublicKey(address);
    this.connecting = false;
    this._connected = false;

    // Bind methods so they keep the proper "this" context
    this.connect = this.connect.bind(this);
    this.disconnect = this.disconnect.bind(this);
    this.signMessage = this.signMessage.bind(this);
    this.signTransaction = this.signTransaction.bind(this);
    this.signIn = this.signIn.bind(this);
  }

  async signIn(input?: SolanaSignInInput): Promise<SolanaSignInOutput> {
    console.error("Solana Sign In not implemented", { input });
    throw new Error("Method not implemented.");
  }

  override get connected(): boolean {
    return this._connected;
  }

  async connect(): Promise<void> {
    if (this.connected || this.connecting) return;

    console.debug("MetaKeepSolanaWalletAdapter connecting");
    this.connecting = true;
    try {
      if (this.readyState !== WalletReadyState.Loadable)
        throw new WalletNotReadyError();

      this._connected = true;
      this.emit("connect", this.publicKey);
    } catch (error: unknown) {
      if (error instanceof WalletError) {
        this.emit("error", error);
        throw error;
      }
      throw new WalletLoadError(errorMessage(error), error);
    } finally {
      this.connecting = false;
    }
  }

  async disconnect(): Promise<void> {
    // Only log and process if connected
    if (!this.connected) return;

    console.debug("MetaKeepWalletAdapter disconnecting");

    // Set connected to false BEFORE emitting disconnect
    // This prevents re-entry into this method
    this._connected = false;
    this.connecting = false;

    this.emit("disconnect");
  }

  async signMessage(message: Uint8Array): Promise<Uint8Array> {
    console.debug("MetaKeepWalletAdapter signing message");

    if (!this.connected) throw new WalletNotConnectedError();

    // Sign message using MetaKeep SDK
    try {
      const signedMessage = await this.sdk.signMessage(
        new TextDecoder().decode(message),
        "sign a message",
      );
      // Convert hex to Uint8Array
      return Buffer.from(signedMessage.signature.slice(2), "hex");
    } catch (error: unknown) {
      console.error("Error signing message", error);
      throw new WalletSignTransactionError(errorMessage(error), error);
    }
  }

  get supportedTransactionVersions(): ReadonlySet<TransactionVersion> {
    return new Set<TransactionVersion>(["0", "legacy"] as TransactionVersion[]);
  }

  async signTransaction<
    T extends TransactionOrVersionedTransaction<U>,
    U extends SupportedTransactionVersions,
  >(transaction: T): Promise<T> {
    console.debug("MetaKeepWalletAdapter signing transaction");

    if (!this.connected) throw new WalletNotConnectedError();

    // Sign transaction using MetaKeep SDK
    try {
      const signedTransaction = await this.sdk.signTransaction(
        transaction,
        "sign a transaction",
      );

      // Add signature to transaction
      const signature = Buffer.from(
        signedTransaction.signature.slice(2),
        "hex",
      );
      transaction.addSignature(this.publicKey!, signature);
      return transaction;
    } catch (error: unknown) {
      console.error("Error signing transaction", error);
      throw new WalletSignTransactionError(errorMessage(error), error);
    }
  }
}
