import { useCallback, useState } from "react";
import type { Web3Client } from "../lib/Web3Client.js";

// a callback that creates wallets for the user
// and triggers a login
export const useCreateWallets = (web3Client: Web3Client | null) => {
  const [walletCreationInProgress, setWalletCreationInProgress] =
    useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [createWalletFinished, setCreateWalletFinished] = useState(false);
  const createWallet = useCallback(async () => {
    setWalletCreationInProgress(true);
    await web3Client?.createWallets().catch((e) => {
      console.error(e);
      setError(e);
      setWalletCreationInProgress(false);
    });
    setCreateWalletFinished(true);
  }, [web3Client]);
  return {
    createWallet,
    createWalletFinished,
    walletCreationInProgress,
    error,
  };
};
